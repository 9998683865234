import React from 'react'

const error = () => {
    return (
        <div>
            404 page not found
        </div>
    )
}

export default error
